.StageSelectorContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    width: calc(100vw - 10em);
    padding: 0.5em;
    margin-bottom: 0.5em;
}

.StageSelectorContainer > div:first-child > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.StageSelectorContainer.Selected {
    background-color: #f9f9f9;
    border-left: 4px solid #6a9be7;
    border-radius: 5px;
}

.StageSelectorContainer > div {
    margin-right: 1em;
}

