.formContractLayoutContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-width: 60em;
  max-height: 75vh;
  min-height: 70vh;
  padding: 2em 0;
  margin: 0;
  overflow: auto;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  left: -1em;
}

.formContractLayoutContainer>div {
  flex: 1;
  width: 35vw;
}

.formContractLayoutContainer>div:first-child {
  flex: 1;
  width: 10vw;
}

.formContractLayoutContainer>div:last-child {
  flex: 1;
  width: 10vw;
}

.formContractLayoutContainer>div {
  margin-right: 1em;
}

.ProtocolStagesNames {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 40em;
}

.ProtocolStagesNames .ms-TextField {
  max-width: 7em;
  border-radius: 3px;
}

.StageItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: solid 1px rgb(68, 68, 68);
  border-radius: 2px;
  margin: 0.2em;
  padding: 0 0.3em;
}

.StageItem>.stageDropDown {
  flex: 1;
}

.StageItem>.ms-TextField {
  flex: 1;
  max-width: unset;
}

.StageItem::nth-child(even) {
  margin: 0.2em;
}

.StageSelectorItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.1em;
  /* margin-bottom: 0.5em; */
  /* border-bottom: dotted 1px rgb(209 206 206); */
}

.StageSelectorItem.Active {
  border-left: solid 4px #6a9be7;
  padding: 1em;
  background-color: #f9f9f9;
  border-radius: 3px;
  margin-bottom: 1.3em;
  border-bottom: none;
}

.StageSelectorItem:last-child {
  border-bottom: none;
}

.StageSelectorItem>.ms-TextField {
  margin: 0 0.5em 0 0;
}

.StageSelectorItem>div>.ms-TextField {
  margin: 0 0.5em 0.5em 0;
}

.StageSelectorItem>.ms-Checkbox {
  min-width: 15em;
}

.StageItem>label {
  min-width: 5em;
}

ul {
  padding-inline-start: unset;
}

.FlowChart {
  height: 81.5vh;
  border-radius: 2px;
  overflow: auto;
  padding: 0 1em 0 0;
}

.SubformButtons {
  margin-top: 1em;
}

.stageDropDown {
  min-width: 10em;
}

.stageDropDown>.ms-Dropdown>span:first-child {
  border: none;
}

.ConceptListViewer {
  padding: 3px;
}

.ConceptListViewer>li {
  border: solid 1px rgb(68, 68, 68);
  border-radius: 2px;
  margin: 0.3em 0;
  padding: 0.2em;
}

.ConceptListViewer>li:hover {
  background-color: #247dbd;
  color: white;
  cursor: pointer;
}

.ConceptListSelector {
  padding: 5px;
  border: solid 1px lightgray;
  border-radius: 5px;
  max-height: 20em;
  overflow: auto;
  margin: unset;
}

.ConceptListSelector>.ms-MessageBar {
  margin-bottom: unset;
}

.ConceptListSelector>.ms-MessageBar>.ms-MessageBar-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.ConceptListSelector>ul {
  margin-top: unset;
}

.ConceptListSelector>ul>li:last-child {
  border-bottom: unset;
}

.ConceptListSelector>ul>li {
  border-bottom: dotted 1px rgb(129, 129, 129);
  margin: 0 0.4em 0 0;
  padding: 0.5em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ConceptListSelector>ul>li>div:nth-child(2) {
  width: 6em;
}

.ConceptListSelector>ul>li>div:nth-child(4) {
  width: 6em;
}

.ConceptListSelector>ul>li:first-child {
  min-width: 30em;
}

.ConceptListSelector>ul>li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ConceptListSelector>ul>li>.ms-Checkbox:first-child {
  flex: 1
}

.ConceptListSelector>ul>li>.ms-TextField {
  margin: 0em 1em;
}

.ItemCounter {
  background-color: #4391c9;
  padding: 5px 10px;
  border-radius: 17px;
  color: white;
  font-size: smaller;
}

.Controls.Concepts {
  padding-left: 5px;
}