.infoPanel {
  min-width: 18em;
}

.priceListStatus {
  border-radius: 3px;
  font-size: large;
  text-align: center;
}

.priceListTag {
  border-radius: 3px;
  padding: 0.1em 0.5em;
}

.pricelist.Active {
  color: #61bd24;
  background-color: #61bd242f;
}

.pricelist.Pending {
  color: #bdb324;
  background-color: #bdb3242f;
}

.pricelist.Expired {
  color: #bd2424;
  background-color: #bd24242f;
}

.pricelist.NoValidity {
  color: #a3a3a3;
  color: #a3a3a32f;
}
