.ContractOptions {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
}

.ContractAssociationHeader {
    max-width: 1em;
    min-width: unset !important;
}

.ContractOptions:nth-child(0) {
    margin-left: 1em;
}

.ContractStatus {
    border-left: solid 6px #5e5e5e;
}

.ContractStatus.Active {
    border-left: solid 6px #00a651;
}

.ContractStatus.Inactive {
    border-left: solid 6px #ffff00;
}

.ContractStatus.Suspended {
    border-left: solid 6px rgb(97, 120, 163);
}

.ContractStatus.Expired {
    border-left: solid 6px #040404;
}

.ContractStatus.Canceled {
    border-left: solid 6px rgb(171, 106, 106);
}

.ContractStatus.Terminated {
    border-left: solid 6px #f00;
}

.LinkIcon {
    border: solid 1px #5e5e5e;
    border-radius: 3px;
    padding: 0.5em;
    background-color: #d2edf8;
    margin-top: 0.2em;
}

