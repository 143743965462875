.protocolTotals {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    border: solid 1px #aeaeae;
    font-size: 1.2em;
    min-width: 10em;
    margin-right: 1em;
    min-height: 6em;
}

.protocolTotals:hover {
    background-color: #f0f0f0;
}

.protocolTotals > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0.5em;
}