.ContractAssociationsControls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1rem;
}

.ContractAssociationsControls > button {
  margin-bottom: 1rem;
}

.ProtocolSpecialty {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}