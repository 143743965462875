.SelectionListContainer {
  overflow: auto;
  max-height: 500px;
  margin-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
  border-bottom: 1px solid lightgrey;
}

.SelectionList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.SelectionList .SelectionList-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2em;
}

.SelectionList .ms-Toggle {
  margin-top: 8px;
  margin-right: 1em;
}

.SelectionList .SelectionList-item > label {
  text-transform: uppercase;
}

li {
  list-style-type: none;
}

.ms-Pivot {
  margin: 1em;
}
