.FieldContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.FieldContainer > * {
  margin-right: .5em;
}

.FieldContainer > div {
  display: flex;
  flex-direction: row;
}