.DetailItemContainer {
    display: flex;
    flex-direction: column;
    border: solid 1px lightgray;
    border-radius: 3px;
    padding: .2em .6em;
    margin-bottom: .3em;
}

.DetailInfo {
    width: 100%;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    flex-direction: row;
}

.DetailItemActions {
    display: flex;
    align-self: flex-start;
    margin-left: 1em;
    padding-top: .3em;
}

.AditionalInfomationContainer {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: space-between;
    overflow: hidden;
    visibility:hidden;
    opacity:0;
    max-height: 0;
    transition:max-height 0.3s ease, visibility 0.3s linear, opacity 0.3s linear;    
    transition-delay: 500ms;
}

.DetailItemContainer:hover {
    background-color: rgb(244, 248, 252);
}

.DetailItemContainer:hover > .AditionalInfomationContainer {
    display: flex;
    visibility: visible;
    opacity: 1;
    max-height: 150em;
    border-top: dashed 1px lightgray;
}

.AditionalInfomationContainer > .DetailItemActions {
    margin-left: auto;
}

.DetailItemContainer.green {
    border-left: solid 5px #28a745;
}

.DetailItemContainer.red {
    border-left: solid 5px #dc3545;
}

.DetailItemContainer.yellow {
    border-left: solid 5px #e5e91f;
}

.DetailItemContainer.warning {
    border-left: solid 5px #e9b31f;
}

.DetailSatusBagded {
    margin-right: 10px;
    font-size: .8em;
    font-weight: lighter;
    color: #fff;
    padding: .5px 7px;
    border-radius: 3px;
    background-color: black;
    margin-left: 1em;
}

.DetailSatusBagded.green {
    background-color: #28a745;
}

.DetailSatusBagded.red {
    background-color: #dc3545;
}

.DetailSatusBagded.yellow {
    background-color: #e5e91f;
    color: black;
}

.DetailSatusBagded.warning {
    background-color: #e9b31f;
    color: black
}

.InformacionContainer {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    padding: .5em;
}

.InformacionContainer > div {
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.rowInformation {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: flex-start;
    width: 100%;
}

.rowInformation > div {
    margin-right: .5em;
}
