.sideMenuBackground {
  background: #247dbd;
  background: linear-gradient(180deg, #247dbd 0, #20ad9b);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}

.sideMenuStickyLeft {
  min-width: 120px;
  max-width: 120px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.sideMenuStickyLeft > img {
  margin-bottom: 4.5em;
  width: 64px;
  display: block;
}

.menuItemIcon {
  width: 42px;
  height: 26px;
  fill: #b8b8b8;
  color: white;
  margin: 0.5em;
}

.menuItem:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.15s ease-in;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 9px;
  color: hsla(0, 0%, 100%, 0.68);
  font: normal normal normal 14px/17px Lato;
  text-decoration: none;
  margin: 0em 0.2em 1.5em 0.2em;
  padding: 0.2em;
}
