.TenantPriceList.Active {
  border-left-color: #61bd24;
  background-color: #61bd242f;
  border-left-width: 5px;
}

.TenantPriceList.Pending {
  border-left-color: #bdb324;
  background-color: #bdb3242f;
  border-left-width: 5px;
}

.TenantPriceList.Expired {
  border-left-color: #bd2424;
  background-color: #bd24242f;
  border-left-width: 5px;
}

.TenantPriceList.NoValidity {
  border-left-color: #a3a3a3;
  background-color: #a3a3a32f;
  border-left-width: 5px;
}

.TenantPriceList > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.TenantPriceList > div > div > i {
  font-size: 15px;
  margin-right: 10px;
  background-color: rgb(255 255 255 / 80%);
  padding: 5px;
  border-radius: 3px;
  margin-top: 0.2em;
}

.TenantPriceList > div > div > label {
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.TenantPriceList > div > div > span {
  font-size: 12px;
  color: white;
  margin-right: 10px;
  background-color: rgba(4, 27, 63, 0.3);
  padding: 3px 5px;
  border-radius: 3px;
}
