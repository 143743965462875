.welcomeText {
  font: normal normal normal 56px Lato;
  color: #247dbd;
  text-align: left;
  margin: 1em;
}

.welcomeText > label > span {
  font: 56px Lato;
  font-weight: 900;
}
