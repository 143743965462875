.flowcharSelector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  height: calc(100vh - 12.7em);
}

.flowcharSelector > div {
  width: 100%;
  height: calc(100vh - 10.2em);
  overflow: auto;
  padding: 0 1em 1em 0em;
}

.flowcharSelector > .flowcharContainer {
  margin-left: 2em;
  width: -webkit-fill-available;
  min-width: 67%;
}