.consumption-settlement-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    justify-content: flex-start;
    border: solid 1px lightgray;
    padding: .5em;
    border-radius: 5px;
}

.consumption-settlement-container > div {
    display: flex;
    flex-direction: column;
}

.consumption-settlement-container :first-child {
    margin-right: 1em;
}