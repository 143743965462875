.primary.Red {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(214, 66, 66);
}

.primary.Yellow {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(214, 204, 66);
}

.primary.Green {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(83, 214, 66);
}

.StatusBackGroundRed {
    background-color: #f5dada;
}

.StatusBackGroundYellow {
    background-color: #f5f5da;
}

.StatusBackGroundGreen {
    background-color: #daf5e0;
}

.StatusBackGroundBlue {
    background-color: #daebf5;
}

.DetailsListContent {
    /* display: flex; */
    flex-direction: row;
    position: relative;
}

.DetailsListContent > div:last-child > div {
    min-width: 20em;
    margin-left: 2em;
    position: fixed;
    border: solid 1px lightgray;
    right: 0;
    padding: 2em;
    background: white;
    border-radius: 3px 0 0 3px;
    border-width: 1px 0px 1px 1px;
    box-shadow: -4px 5px 13px 4px rgba(143,137,137,0.75);
    -webkit-box-shadow: -4px 5px 13px 4px rgba(143,137,137,0.75);
    -moz-box-shadow: -4px 5px 13px 4px rgba(143,137,137,0.75);
}

.ItemCounter {
    background-color: #efefef;
    padding: 12px 10px;
    border-radius: 3px;
    color: #474646;
    font-size: smaller;
    flex-wrap: nowrap;
    min-width: 10em;
    text-align: center;
}

.menurow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0.5em 0;
    border: solid 1px lightgray;
    border-radius: 3px;
    padding: .2em;
}

.Statistics {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.Statistics > button {
    border: solid 1px lightgray !important;
    border-radius: 0 !important;
}

.Statistics > button:first-child {
    border-radius: 3px 0 0 3px !important;
}

.Statistics > button:last-child {
    border-radius: 0 3px 3px 0 !important;
}

button.totalItems {
    border-bottom: solid 3px gray !important;
}

button.itemizableItems {
    border-bottom: solid 3px gray !important;
}

button.reembolsableItems {
    border-bottom: solid 3px gray !important;
}

button.excluedItems {
    border-bottom: solid 3px rgb(214, 66, 66) !important;
}

button.okItems {
    border-bottom: solid 3px green !important;
}

button.reviewItems {
    border-bottom: solid 3px rgb(214, 204, 66) !important;
}

.SelectColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.DetailsStage {
    color: #247dbd; 
    text-transform: uppercase;
}
