.SummaryItemContainer {
    display: flex;
    flex-direction: column;
    border: solid 1px lightgray;
    border-radius: 3px;
    padding: .2em .6em;
    margin-bottom: .3em;
}

.SummaryInfo {
    width: 100%;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    flex-direction: row;
}

.AditionalInfomationContainer {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: space-between;
    /* overflow: hidden;
    visibility: visible; */
    /* opacity:0;
    max-height: 0; */
    /* transition:max-height 0.3s ease, visibility 0.3s linear, opacity 0.3s linear;    
    transition-delay: 500ms; */
}

.SummaryItemContainer:hover > .AditionalInfomationContainer {
    display: flex;
    /* visibility: visible;
    opacity: 1;
    max-height: 100em; */
    border-top: dashed 1px lightgray;
}

.SummaryItemActions {
    display: flex;
    align-self: flex-start;
}

.SummaryItemContainer:hover {
    background-color: rgb(244, 248, 252);
}

.SummaryItemContainer > .SummaryItemActions {
    margin-left: auto;
}

.SummaryItemContainer.warning {
    /* border-color: #ebd517; */
    border-left: solid 5px #ebd517;
}

ul.TotalList {

    list-style-type: none;
    padding: 0;
    margin: 0;
    min-width: 50%;
}

ul.TotalList > li {
    display: flex;
    justify-content: space-between;
    padding: .2em 0;
    flex-direction: column;
    align-items: self-end;
    min-width: 50%;
}

ul.TotalList > li > p {
    display: flex;
    flex-direction: column;
    align-items: self-end;
}

.InformacionContainer {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-between;
    width: 100%;
    padding: .5em;
}

.SummaryStatistics {
    margin-top: .3em;
}

table.values {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border: solid 1px #aeaeae;
    border-radius: 4px;
}

table.values thead {
    background-color: #d8d7d7;
    border: solid 1px #aeaeae;
    padding: 0.5em;
}

table.values thead  th {
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    border-left: solid 1px #aeaeae;
}

table.values tbody  th {
    background-color: #ececec;
    font-weight: bold;
    border-bottom: solid 1px #aeaeae;
}

table.values tbody  td {
    padding: 0.3em;
    font-size: 1em;
    font-family: Tahoma, Verdana, sans-serif;
    font-weight: normal;
    background-color: #ffffff;
    border-left: solid 1px #aeaeae;
    border-bottom: solid 1px #aeaeae;
    border-top: solid 1px #aeaeae;
    text-align: end;
}

table.SettlementSummaryTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-family: Tahoma, Verdana, sans-serif;
    font-weight: normal;
    font-size: 1em;
}

table.SettlementSummaryTable thead {
    padding: 0.5em;
    border-bottom: solid 1px #aeaeae;
}

table.SettlementSummaryTable thead  th {
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    border-left: solid 1px #aeaeae;
    vertical-align: text-bottom;
}

table.SettlementSummaryTable thead  th:first-child {
    border-left: none;
}

table.SettlementSummaryTable tbody  tr {
    border-bottom: solid 1px #aeaeae;
}

table.SettlementSummaryTable tbody  td {
    padding: 5px;
}
table.SettlementSummaryTable tbody  tr:hover {
    background-color: #cccccc;
    border: solid 1px #aeaeae;
    border-radius: 5px;
}

