.priceListStatus {
  border-radius: 3px;
  font-size: large;
  text-align: center;
}

.pricelist.Active {
  color: #61bd24;
}

.pricelist.Pending {
  color: #bdb324;
}

.pricelist.Expired {
  color: #bd2424;
}

.pricelist.NoValidity {
  color: #a3a3a3;
}
