.VisitActivity {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 0;
    padding: .4rem;
    border: 1px solid #adadad;
    border-radius: 2px;
    background-color: #f9f9f9;
    align-items: center;
    justify-content: center;
    min-height: 1.2em;
}

.VisitActivity.cirle {
    border-radius: 50%;
    width: 1.2em;
    height: .8em;
}

.statusBadge {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.statusBadge > span:first-child {
  margin-right: 1em;
}

.statusBadge > label {
  font-size: medium;
  font-weight: bold;
}

.tableContainer {
  overflow: auto;
  border: solid 1px lightgray;
  border-radius: 5px;
  position: relative;
  padding: 0 1em 1em 1em;
  max-width: calc(100vw - 16em);

}

/* keep button on top of the div */
.visitButton {
  position: sticky;
  top: 1em;
  left: 0em;
  z-index: 10; 
}

.visitButton > button {
  margin: 0 1em 0 0;
}

.VisitActivity.Completed {
    background-color: #168f30;
    border: solid 2px #168f30;
}

.VisitActivity.Failed {
    background-color: #f05e39;
}

.VisitActivity.Skipped {
    background-color: #666664;
}

.VisitActivity.Started {
    border: solid 2px #55e975;
}

.VisitActivity.AutoCompleted {
  border: solid 2px #eeb64f;
}

.VisitActivity.Changed {
  border: solid 2px rgb(57, 119, 190);
}

.visitContainer > p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}

.visitContainer > p > svg {
  margin-right: .5em;
}



.VisitActivity.warning {
    color: #af1c09;
    font-weight: bold;
    border: solid 1px #af1c09;
}

.table-header-rotated thead > tr {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-header-rotated thead tr:first-child {
  background-color: #ffffff;
}

.table-header-rotated thead > th:first-child {
  left: 0;
  z-index: 2;
}


.table-header-rotated thead th:first-child {
  /* Ensure this stays above the emulated border right in tbody th {}: */
  background-color: #FFF;
  position: sticky;
  left: 0;
  z-index: 1;
}

.table-header-rotated tbody th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
}

.table-header-rotated tbody th {
  background: #FFF;
  /* border-right: 1px solid #CCC;  */
  /* Browsers tend to drop borders on sticky elements, so we emulate the border-right using a box-shadow to ensure it stays: */
  /* box-shadow: 1px 0 0 0 #ccc; */
}

.table-header-rotated th.row-header{
    width: auto;
  }

  .table-header-rotated > thead {
    border-bottom: solid 1px #2e2e2e;
  }
  
  .table-header-rotated td{
    width: 40px;
    vertical-align: middle;
    text-align: left;
  }

  .table-header-rotated td > div:hover {
    background-color: #cecece;
    cursor: pointer;
  }

  .table-header-rotated tr:hover > th:first-child {
    background-color: #ebebeb;
    font-weight: bold;
  }

  .table-header-rotated thead > tr:hover > th:first-child {
    background-color: unset;

  }

  .table-header-rotated td:first-child {
    padding-left: 1em;
  }

  .table-header-rotated > thead > tr:first-child {
    height: 10em;
    vertical-align: bottom;
  }

  .table-header-rotated td:first{
    min-width: 15em;
    vertical-align: bottom;
    text-align: left;
  }
  
  .table-header-rotated th.rotate-45{
    height: 80px;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    position: relative;
    vertical-align: bottom;
    padding: 0;
    font-size: 12px;
    line-height: 0.8;
  }

  .table-header-rotated th:first-child {
    width: 20em;
  }
  
  .table-header-rotated th.rotate-45 > div{
    position: relative;
    top: 0px;
    left: 70px; /* 80 * tan(45) / 2 = 40 where 80 is the height on the cell and 45 is the transform angle*/
    height: 100%;
    -ms-transform:skew(-45deg,0deg);
    -moz-transform:skew(-45deg,0deg);
    -webkit-transform:skew(-45deg,0deg);
    -o-transform:skew(-45deg,0deg);
    transform:skew(-45deg,0deg);
    overflow: hidden;
    border-right: 1px solid #adadad;
    background-color: #ffffff;
  }
  
  .table-header-rotated th.rotate-45 span {
    -ms-transform:skew(45deg,0deg) rotate(315deg);
    -moz-transform:skew(45deg,0deg) rotate(315deg);
    -webkit-transform:skew(45deg,0deg) rotate(315deg);
    -o-transform:skew(45deg,0deg) rotate(315deg);
    transform:skew(45deg,0deg) rotate(315deg);
    position: absolute;
    bottom: 30px; /* 40 cos(45) = 28 with an additional 2px margin*/
    left: -25px; /*Because it looked good, but there is probably a mathematical link here as well*/
    display: inline-block;
    width: 85px; /* 80 / cos(45) - 40 cos (45) = 85 where 80 is the height of the cell, 40 the width of the cell and 45 the transform angle*/
    text-align: left;
    white-space: nowrap; /*whether to display in one line or not*/
  }

  .table-header-rotated tr:hover > td > span {
    font-weight: bold;
  }

  .table-header-rotated tr:hover > td:first-child {
    border-bottom: dotted 1px rgb(139, 139, 139);
    background-color: #c9c9c9;
    border-radius: 3px 0 0 3px;
  }

  .fui-MessageBar {
    margin-bottom: .5em;
  }