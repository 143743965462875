/* .table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0px solid #ddd;
}

.table th,
.table td {
  border-bottom: 1px solid #ddd;
  padding: 3px;
  max-width: 10px;
  text-align: left;
  padding-top: 8px;
}

.table th:first-child,
.table td:first-child {
  text-align: left;
  text-transform: capitalize;
  max-width: 30px;
}

.table th:last-child,
.table td:last-child {
  text-align: left;
  text-transform: capitalize;
  max-width: unset;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

.serviceDetailsOptions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.ms-Toggle {
  margin-bottom: unset;
  margin-right: 5px;
}

.TableBodyRow:hover {
  background-color: #f2f2f2;
}
