.priceListDetailsContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  /* width: calc(100vw - 450px); */
}

.priceListDetailsContent > div:first-child {
  /* width: calc(100vw - 450px - 180px); */
  flex: 1;
  margin: 0 2em;
}

.priceListDetailsContent label.badge {
  display: block;
}

.infoPanel {
  min-width: 250px;
  margin-left: 1em;
  align-self: flex-start;
}

.emptyMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
