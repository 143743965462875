button.fui-Tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: unset !important;
    height: 2em;
    padding-top: 5px;
}

.fui-Persona__primaryText {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11em;
}