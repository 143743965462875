.MedicalServiceAssociatedBlock {
}

.MedicalServiceAssociatedBlock > ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 1px #e0e0e0;
  border-radius: 0.3em;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
}

.MedicalServiceAssociatedBlock > ul > li:hover {
  background-color: #f5f5f5;
}

.MedicalServiceAssociatedBlock > ul > li > * {
  margin-left: 1em;
}
