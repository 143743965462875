.ToggleInline {
    margin-top: .5em;
}

.ToggleInline .fui-Switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ToggleInline .fui-Switch > label {
    font-weight: 500;
    margin-left: -8px;
}
