.ConsumptionToSettleContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    padding: 1em;
    border: solid 1px #148df0;
    border-radius: 5px;
    margin-top: 1em;
}

.ConsumptionToSettleContainer > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    margin-right: 2em;
}

.ConsumptionToSettleContainer > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
}

.ConsumptionToSettleContainer > div > div > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
}

.ConsumptionToSettleContainer > div > div > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    padding: .5em;
}